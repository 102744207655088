.course-btns .btn{
  margin:5px;
}
.clickable{cursor: pointer;}
.caseStudyBox h5{display: block; width: 100%;}
@media screen and (min-width:768px){
  .App{
    display: flex;
  }
  .caseStudyBox{
    max-width: 30vw;
    border-right:1px solid #000000;
    background: #d5e6ed;
    min-height: 100vh;
  }
}